function trigger(action, ns, html, params = {}) {
  const eventName = [ns, action, 'fm'].join('.');
  const data = { modal: action, html };
  params || (params = {}); // eslint-disable-line

  if (typeof params === 'object') {
    data.params = params;
    if ('id' in params) { data.id = params.id; }
  } else {
    data.id = params;
  }

  $(document).trigger(eventName, data);
}

App.Modal = function Modal(ns, html, params) {
  const modal = document.createElement('ef-modal');
  modal.namespace = ns;
  modal.innerHTML = html;
  document.body.insertAdjacentElement('beforeend', modal);

  trigger('show', ns, html, params);
  trigger('any', ns, html, params);
};

App.Modal.create = function create(ns, html, params) {
  trigger('create', ns, html, params);
  trigger('any', ns, html, params);
  document.querySelector('ef-modal')?.dismiss();
};

App.Modal.update = function update(ns, html, params) {
  trigger('update', ns, html, params);
  trigger('any', ns, html, params);
  document.querySelector('ef-modal')?.dismiss();
};

App.Modal.refresh = function refresh(ns, html, params) {
  trigger('refresh', ns, html, params);
  trigger('any', ns, html, params);
};

$(document)
  // Used for search filter modal
  .on('click', '[data-modal-content]', (e) => {
    e.preventDefault();

    const content = $(e.currentTarget.getAttribute('data-modal-content')).html();
    content !== undefined && App.Modal('customModal', content);
  });
