import Turbolinks from 'turbolinks';

document.addEventListener('turbolinks:load', () => {
  const element = document.querySelector('[data-js-turbolinks-visit]');

  if (!element) { return; }

  const url = element.dataset.jsTurbolinksVisit;
  Turbolinks.visit(url, { action: 'replace' });
});
