import Turbolinks from 'turbolinks';

$(document).on('ajax:beforeSend', '.js-turbolinks-get-form', (event) => {
  event.preventDefault();

  const [xhr, options] = event.detail; // eslint-disable-line no-unused-vars

  Turbolinks.clearCache();
  Turbolinks.visit(options.url, { action: 'replace' });
});
