import I18n from 'i18n';

const selector = 'textarea.js-rte:not(.medium-editor-hidden)';

const destroyEditors = () => {
  window._mediumEditors && window._mediumEditors.map(e => e && e.parentNode && e.destroy()); // eslint-disable-line no-underscore-dangle
};

function initializeEditor(existingElements) {
  const elements = existingElements || document.querySelectorAll(selector);
  if (!elements.length) { return; }

  import('medium-editor').then(component => component.default).then((MediumEditor) => {
    new MediumEditor(elements, {
      anchor: {
        linkValidation: true,
      },
      placeholder: {
        text: I18n.t('group.description_placeholder'),
      },
      toolbar: {
        buttons: ['bold', 'italic', 'underline', 'anchor', 'unorderedlist', 'orderedlist', 'h2', 'quote'],
      },
    });
  });
}

document.addEventListener('turbolinks:before-cache', destroyEditors);

// Install observer
function editorObserver(n) {
  initializeEditor(n.querySelectorAll(selector));
}

App.mutation_observers.push(editorObserver);

document.addEventListener('turbolinks:load', () => {
  destroyEditors();
  initializeEditor();
});
