import { reloadElement } from 'src/utils';

$(document).on('group_join_request.update.fm', (e, data) => {
  $(`[data-hide-id=${data.id}]`).slideUp(200);
});

$(document).on('inlineForm-submit', '[data-id="invite_email"]', () => {
  reloadElement('.js-group-invites');
});

$(document).on('inlineForm-submit', '[data-id="invite_existing_user"]', () => {
  reloadElement('.js-group-invites');
});
