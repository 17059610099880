import Snack, { Snackbar } from '@effectivastudio/snackbar';

Snackbar.configure({
  pos: 'bottom-right',
  backgroundColor: null,
  textColor: null,
  actionTextColor: null,
  customClass: 'snackbar-default',
  actionText: '<i class="icon-close"></i>',
});

function flashToSnack(element) {
  Snack({ text: element.innerHTML });
  element.parentNode.removeChild(element);
}

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.js-flash-to-snack').forEach(flashToSnack);
});

App.mutation_observers.push((n) => {
  const elements = n.classList.contains('js-flash-to-snack') ? [n] : n.querySelectorAll('.js-flash-to-snack');
  elements.forEach(flashToSnack);
});
