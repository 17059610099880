// *** FUNCTIONALITY *** //
// Set unique checkbox which will uncheck all other checkbox with the same unique-target name
// data-unique="true" checkbox will uncheck all data-unique="false" checkboxes
// data-unique="false" checkbox will uncheck data-unique="true" checkbox

// *** HOW TO USE IT *** //
// <input type="checkbox" data-unique="false" data-unique-target="uniq_name_1">
// <input type="checkbox" data-unique="false" data-unique-target="uniq_name_1">
// <input type="checkbox" data-unique="true" data-unique-target="uniq_name_1">

$(document)
  .on('click', 'input[data-unique][type="checkbox"]', (e) => {
    const d = e.currentTarget.dataset;
    const isUnique = (d.unique === 'true');
    const el = document.querySelectorAll(`[data-unique-target=${d.uniqueTarget}][data-unique=${!isUnique}]`);

    el.forEach((item) => {
      item.checked = false;
    });
  });
