import store from 'store2';

const { APP_PREVIOUS_VERSION, APP_VERSION, IS_MOBILE } = App.Mobile;

// 432 000 000 milliseconds
const FIVE_DAYS = 5 * 60 * 60 * 24 * 1000;

const loggedIn = App.config.user.id;

const STORAGE_KEY = 'updateAppNoticeShownAt';

const notifiedAt = parseInt(store.get(STORAGE_KEY) || 0, 10);
const NOTICE_NOT_SEEN_IN_LAST_5_DAYS = Math.abs(notifiedAt - Date.now()) >= FIVE_DAYS;

const shouldShowNotice = IS_MOBILE
  && APP_VERSION <= APP_PREVIOUS_VERSION
  && loggedIn
  && NOTICE_NOT_SEEN_IN_LAST_5_DAYS
  && !store.isFake(); // Don't nag people if they localStorage is not working and data is saved in memory only

if (shouldShowNotice) {
  document.addEventListener('turbolinks:load', () => {
    store.set(STORAGE_KEY, Date.now());
    setTimeout(() => $.get('/modals/outdated_app'), 2000);
  });
}
