/**
 * Howler throws error on window.unload:
 * InvalidStateError: An attempt was made to use an object that is not, or is no longer, usable
 */

// import { Howl } from 'howler';
import _debounce from 'lodash/debounce';
import popMp3 from 'pop.mp3';
import popOgg from 'pop.ogg';

// Delete from global scope
delete (window.HowlerGlobal);
delete (window.Howler);
delete (window.Howl);
delete (window.Sound);

let notificationSound;

const play = _debounce(() => notificationSound.play(), 100);

export default function audio() {
  if (App.config.runtime.isMobileApp) { return; }

  import('howler').then(({ Howl }) => {
    notificationSound = notificationSound || new Howl({
      src: [popOgg, popMp3],
    });

    play();
  });
}
