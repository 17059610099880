export function timestampToDate(timestamp) {
  let t = `${timestamp}00000000`;
  [t] = t.replace('.', '').match(/^\d{0,13}/);
  t = parseInt(t, 10);
  return new Date(t);
}

export function uuid() {
  let result = '';
  let i;
  let random;
  for (i = 0; i < 32; i++) {
    random = Math.random() * 16 | 0; // eslint-disable-line

    if (i === 8 || i === 12 || i === 16 || i === 20) {
      result += '-';
    }
    result += (i === 12 ? 4 : (i === 16 ? (random & 3 | 8) : random)).toString(16); // eslint-disable-line
  }
  return result;
}

export default null;
