import { ItemDom, Item } from '@effectivastudio/smart_collapsed';
import { timestampToDate } from 'src/lib/pure_utils';
import SmartCollapsedProjectRules from 'src/smart_collapsed_project_rules';

ItemDom.parseElement = el => ({
  card_title: el.querySelector('[slot="title"]').innerText,
  card_publisher: el.ownerId,
  date: timestampToDate(el.dataset.publishedAt),
});

// Rules are in separate file because of testing
Item.rules = SmartCollapsedProjectRules;

const smartCollapsed = el => ItemDom.fromElement(el);

let shouldWorkResult;
const shouldWork = () => shouldWorkResult = shouldWorkResult || document.querySelector('.js-smart-collapse');

function SmartCollapsedObserver(n) {
  if (!n.classList.contains('card')) { return; }
  if (!shouldWork()) { return; }

  smartCollapsed(n);
}

App.mutation_observers && App.mutation_observers.push(SmartCollapsedObserver);

document.addEventListener('turbolinks:load', () => {
  ItemDom.reset();
  shouldWorkResult = null;
  shouldWork() && document.querySelectorAll('.card:not(.featured)').forEach(smartCollapsed);
});

$(document).on('ajax:success', '.js-filter-form-modal', () => {
  ItemDom.reset();
});
