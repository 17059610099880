import { Rule, SameAttributesRule } from '@effectivastudio/smart_collapsed';

export default [
  // Same card_title + same card_publisher
  SameAttributesRule({
    collapseAfter: 1,
    attributes: ['card_title', 'card_publisher'],
  }),

  // Same card_publisher + within 60 minutes period + after 5 cards

  // {
  //   publisher_and_created_at: {
  //     card_publisher_id: [item, item, item]
  //   },
  // }
  new Rule('publisher_and_created_at', (rule, item) => {
    const key = item.attributes.card_publisher;
    const lastResult = rule.index.last(key);
    const length = rule.index.length(key);

    rule.index.push(key, item);

    if (!lastResult) { return false; }

    const isWithinPeriod = Math.abs(lastResult.attributes.date - item.attributes.date) < Rule.minutes(60);

    !isWithinPeriod && rule.index.write(key, []);

    return length >= 5 && isWithinPeriod;
  }),
];
