import I18n from 'i18n';

// Online users

let onlineUserIds = [];

const isOnline = id => onlineUserIds.find(onlineId => onlineId == id); // eslint-disable-line eqeqeq

const updateElement = (element) => {
  const id = element.dataset.userStatus;
  element.classList[isOnline(id) ? 'add' : 'remove']('active');

  $(element)
    .attr('title', isOnline(id) ? I18n.t('common.online') : I18n.t('common.offline'))
    .tooltip('fixTitle');
};

const equal = (oldState, newState) => oldState.join() === newState.join();

const updateDom = () => document.querySelectorAll('[data-user-status]').forEach(updateElement);

document.addEventListener('sync', ({ detail }) => {
  const newOnlineUserIds = detail.online_users || [];
  if (equal(onlineUserIds, newOnlineUserIds)) { return; }

  onlineUserIds = newOnlineUserIds;
  updateDom();
});

document.addEventListener('turbolinks:load', updateDom);

App.onlineUserIds = () => onlineUserIds;
