import Turbolinks from 'turbolinks';

// Monkey patch Turbolinks to render 403, 404 & 500 normally
// See https://github.com/turbolinks/turbolinks/issues/179

/*
 * Add 404 to list of codes so it is treated as valid response
 * This will also render server-side response instead of native screen on mobile apps
 */

const isTurboApp = navigator.userAgent.includes('Turbo Native');

// Old Turbolinks app should render server side 404 pages
// while new Turbo will handle it as native screens
if (!isTurboApp) {
  Turbolinks.HttpRequest.prototype.requestLoaded = function requestLoaded() {
    return this.endRequest(() => {
      const code = this.xhr.status;
      if ((code >= 200 && code < 300) || code === 404) {
        this.delegate.requestCompletedWithResponse(
          this.xhr.responseText,
          this.xhr.getResponseHeader('Turbolinks-Location'),
        );
      } else {
        this.failed = true;
        this.delegate.requestFailedWithStatusCode(code, this.xhr.responseText);
      }
    });
  };
}
