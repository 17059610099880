// TODO: enableClientSideValidations are not working properly

$(document)
  .on('ajax:success', 'form.js-card-comments', function ajaxSuccessCardCommentWrapper(e) {
    const { response } = e.detail[2];
    const $response = $(response);

    // Clear previous error messages
    $(this).find('.input-msg').remove();

    const hasErrors = $response.find('.invalid:first').length;

    if (!hasErrors) {
      $(this).find('textarea').val('');
      $('.js-auto-size', this).trigger('input').trigger('keyup').trigger('focus');
    }
  })

  // Clear comment form on discard and focus on textarea
  .on('click', '.js-comment-discard', (e) => {
    e.preventDefault();
    const parent = e.currentTarget.closest('form');
    const textareas = parent.querySelectorAll('textarea');

    textareas.forEach(t => t.value = '');
    $(textareas[0]).trigger('keyup');
    textareas[0].focus();
  });

// CARD MIGRATION: New code
document.addEventListener('fragmentInclude:replaced', (e) => {
  e.target
    .querySelectorAll('xs-comment:not([repliesCount="0"])')
    .forEach(el => el.open = true);

  $(e.target).find('form').enableClientSideValidations();
});
