import triggerEvent from 'src/lib/utils/trigger_event';

/**
 * Live data
 * =========
 *
 * Used for auto-updating HTML elements with ajax.
 *
 * If request is made via AJAX;
 *   - server responds with HTML
 *   - element id or attribute data-live="XXX" is the same
 *   - Javascript will update element with new one
 *
 * Forms with remote:true are added automatically.
 */

const attrMap = {
  className: 'class',
};

const copyProperty = (attr, source, target) => {
  if (attr in target) {
    target[attr] = source[attr];

    const domAttr = attrMap[attr];

    if (domAttr && !source.hasAttribute(domAttr)) {
      target.removeAttribute(domAttr);
    }
  }
};

const createCopier = (source, target) => attr => copyProperty(attr, source, target);

const copyElementProperties = ({ source, target }) => {
  const copier = createCopier(source, target);
  copier('innerHTML');
  copier('className');
  copier('defaultValue');
  copier('value');
  return target;
};

const updateElements = (newHtml) => {
  if (!newHtml) { return; }

  const temp = document.createElement('div');
  temp.innerHTML = newHtml;

  requestAnimationFrame(() => {
    let i = 0;

    temp.querySelectorAll('[data-live], form[data-remote="true"]').forEach((source, index, array) => {
      i++;

      const selector = source.id ? `#${source.id}` : `[data-live="${source.dataset.live}"]`;
      document.querySelectorAll(selector)
        .forEach(target => copyElementProperties({ source, target }));

      if (i === array.length) {
        triggerEvent(document, 'live:done');
      }
    });
  });
};

const LiveData = (xhr) => {
  if (!xhr) { return; }

  const contentType = xhr.getResponseHeader('content-type') || '';
  if (!contentType.includes('html')) { return; }

  updateElements(xhr.response);

  // workerStart();
};

document.addEventListener('ajax:success', (e) => {
  const { detail } = e;
  const xhr = detail && detail[2];

  if (!detail) {
    console.warn('Live data', e);
  }

  LiveData(xhr);
});

export const __private__ = {
  copyElementProperties,
  updateElements,
};

export default LiveData;
