// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
const b64EncodeUnicode = str => btoa(encodeURIComponent(str).replace(
  /%([0-9A-F]{2})/g,
  (match, p1) => String.fromCharCode(`0x${p1}`),
));

const b64DecodeUnicode = str => decodeURIComponent(atob(str).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

export const b64EncodeUnicodeUrlSafe = str => b64EncodeUnicode(str).replace(/\//g, '_').replace(/\+/g, '-');
export const b64DecodeUnicodeUrlSafe = str => b64DecodeUnicode(str.replace(/_/g, '/').replace(/-/g, '+'));

export const encodeData = data => b64EncodeUnicodeUrlSafe(JSON.stringify(data));
export const decodeData = data => JSON.parse(b64DecodeUnicodeUrlSafe(data));
