import I18n from 'i18n';
import Snack from '@effectivastudio/snackbar';
import TurboNativeAdapter from '@effectivastudio/turbo_native_adapter';

const Mobile = TurboNativeAdapter;

function extractFilenameFromUrl(url) {
  return decodeURI(url).split('/').slice(-1)[0].split('?')[0];
}

// eslint-disable-next-line import/prefer-default-export
export function downloadFile(e) {
  if (Mobile.IS_MOBILE) {
    e.preventDefault();
    const url = e.currentTarget.dataset.val || e.currentTarget.href;
    const filename = e.currentTarget.getAttribute('download') || e.currentTarget.dataset.downloadName || extractFilenameFromUrl(url);
    Mobile.preview ? Mobile.preview(url, filename) : Mobile.downloadImage(url);
  }

  Snack({ text: I18n.t('snack.downloading') });
}

$(document).on('click', '[href*="/download/"], .js-download-image, .iv-fullscreen-download', downloadFile);
