/**
 * We are using `useBuiltIns: 'entry'` which will polyfill everything for the target browsers
 * which are specified via `.browserslistrc`.
 *
 * We are not using `usage` as there is a very little difference in size between usage and entry
 * and with entry we ensure that all polyfills will be applied.
 *
 * With usage there is a problem of detecting code in node_modules and it needs to have additional configuration
 *
 * TL;DR;
 * =====
 * + We are using entry
 * + it will polyfill everything based on `.browserslistrc`.
 * + there is negligible increase of js size but overall configuration is much safer
 */

// We need to add this import when using 'entry' option
import 'core-js';
import 'regenerator-runtime/runtime';

// Non core-js polyfills
import 'src/patches/element_closest';
import 'src/patches/custom_event';
import 'src/patches/formdata_polyfill';
import 'src/patches/class_list';

import 'intersection-observer'; // Safari support < 12.1
import { ResizeObserver } from '@juggle/resize-observer'; // Safari support <= 12.1

// eslint-disable-next-line compat/compat
window.ResizeObserver ||= ResizeObserver;
