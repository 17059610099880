/* eslint-disable */

// Base is used from:
// https://github.com/jimmywarting/FormData

const global = typeof window === 'object'
  ? window
  : typeof self === 'object' ? self : this;

// keep a reference to native implementation
const _FormData = global.FormData;

// To be monkey patched
const _send = global.XMLHttpRequest && global.XMLHttpRequest.prototype.send;
const _fetch = global.Request && global.fetch;
const _sendBeacon = global.navigator && global.navigator.sendBeacon;

// Unable to patch Request constructor correctly
// const _Request = global.Request
// only way is to use ES6 class extend
// https://github.com/babel/babel/issues/1966

const stringTag = global.Symbol && Symbol.toStringTag;

// Add missing stringTags to blob and files
if (stringTag) {
  if (!Blob.prototype[stringTag]) {
    Blob.prototype[stringTag] = 'Blob';
  }

  if ('File' in global && !File.prototype[stringTag]) {
    File.prototype[stringTag] = 'File';
  }
}

// Fix so you can construct your own File
try {
  new File([], '') // eslint-disable-line
} catch (a) {
  global.File = function File(b, d, c) {
    // IE 11 throws "Error: Could not complete the operation due to error 800a138f" if c is undefined
    const blob = new Blob(b, c || {});
    const t = c && undefined !== c.lastModified ? new Date(c.lastModified) : new Date();

    Object.defineProperties(blob, {
      name: {
        value: d,
      },
      lastModifiedDate: {
        value: t,
      },
      lastModified: {
        value: +t,
      },
      toString: {
        value() {
          return '[object File]';
        },
      },
    });

    if (stringTag) {
      Object.defineProperty(blob, stringTag, {
        value: 'File',
      });
    }

    return blob;
  };
}

function normalizeValue([name, value, filename]) {
  if (value instanceof Blob) {
    // Should always returns a new File instance
    // console.assert(fd.get(x) !== fd.get(x))
    value = new File([value], filename, {
      type: value.type,
      lastModified: value.lastModified,
    });
  }

  return [name, value];
}

function ensureArgs(args, expected) {
  if (args.length < expected) {
    throw new TypeError(`${expected} argument required, but only ${args.length} present.`);
  }
}

function normalizeArgs(name, value, filename) {
  return value instanceof Blob
    // normalize name and filename if adding an attachment
    ? [String(name), value, filename !== undefined
      ? `${filename}` // Cast filename to string if 3th arg isn't undefined
      : typeof value.name === 'string' // if name prop exist
        ? value.name // Use File.name
        : 'blob'] // otherwise fallback to Blob

    // If no attachment, just cast the args to strings
    : [String(name), String(value)];
}

// normalize linefeeds for textareas
// https://html.spec.whatwg.org/multipage/form-elements.html#textarea-line-break-normalisation-transformation
export function normalizeLinefeeds(value) {
  return value.replace(/\r\n/g, '\n').replace(/\n/g, '\r\n');
}

function each(arr, cb) {
  for (let i = 0; i < arr.length; i++) {
    cb(arr[i]);
  }
}

/**
 * @implements {Iterable}
 */
class FormDataPolyfill {
  /**
   * FormData class
   *
   * @param {HTMLElement=} form
   */
  constructor(form) {
    this._data = [];

    if (!form) return this;

    const self = this;

    each(form.elements, (elm) => {
      if (!elm.name || elm.disabled || elm.type === 'submit' || elm.type === 'button') return;

      if (elm.type === 'file') {
        const files = elm.files && elm.files.length
          ? elm.files
          : [new File([], '', { type: 'application/octet-stream' })]; // #78

        each(files, (file) => {
          self.append(elm.name, file);
        });
      } else if (elm.type === 'select-multiple' || elm.type === 'select-one') {
        each(elm.options, (opt) => {
          !opt.disabled && opt.selected && self.append(elm.name, opt.value);
        });
      } else if (elm.type === 'checkbox' || elm.type === 'radio') {
        if (elm.checked) self.append(elm.name, elm.value);
      } else {
        const value = elm.type === 'textarea' ? normalizeLinefeeds(elm.value) : elm.value;
        self.append(elm.name, value);
      }
    });
  }

  /**
   * Append a field
   *
   * @param   {string}           name      field name
   * @param   {string|Blob|File} value     string / blob / file
   * @param   {string=}          filename  filename to use with blob
   * @return  {undefined}
   */
  append(name, value, filename) {
    ensureArgs(arguments, 2);
    const [a, s, d] = normalizeArgs.apply(null, arguments);
    this._data.push([a, s, d]);
  }

  /**
   * Delete all fields values given name
   *
   * @param   {string}  name  Field name
   * @return  {undefined}
   */
  delete(name) {
    ensureArgs(arguments, 1);
    const res = [];
    name = String(name);

    each(this._data, (entry) => {
      if (entry[0] !== name) {
        res.push(entry);
      }
    });

    this._data = res;
  }

  /**
   * Iterate over all fields as [name, value]
   *
   * @return {Iterator}
   */
  * entries() {
    for (let i = 0; i < this._data.length; i++) {
      yield normalizeValue(this._data[i]);
    }
  }

  /**
   * Iterate over all fields
   *
   * @param   {Function}  callback  Executed for each item with parameters (value, name, thisArg)
   * @param   {Object=}   thisArg   `this` context for callback function
   * @return  {undefined}
   */
  forEach(callback, thisArg) {
    ensureArgs(arguments, 1);
    for (const [name, value] of this) {
      callback.call(thisArg, value, name, this);
    }
  }

  /**
   * Return first field value given name
   * or null if non existen
   *
   * @param   {string}  name      Field name
   * @return  {string|File|null}  value Fields value
   */
  get(name) {
    ensureArgs(arguments, 1);
    const entries = this._data;
    name = String(name);
    for (let i = 0; i < entries.length; i++) {
      if (entries[i][0] === name) {
        return normalizeValue(this._data[i])[1];
      }
    }
    return null;
  }

  /**
   * Return all fields values given name
   *
   * @param   {string}  name  Fields name
   * @return  {Array}         [{String|File}]
   */
  getAll(name) {
    ensureArgs(arguments, 1);
    const result = [];
    name = String(name);
    for (let i = 0; i < this._data.length; i++) {
      if (this._data[i][0] === name) {
        result.push(normalizeValue(this._data[i])[1]);
      }
    }

    return result;
  }

  /**
   * Check for field name existence
   *
   * @param   {string}   name  Field name
   * @return  {boolean}
   */
  has(name) {
    ensureArgs(arguments, 1);
    name = String(name);
    for (let i = 0; i < this._data.length; i++) {
      if (this._data[i][0] === name) {
        return true;
      }
    }
    return false;
  }

  /**
   * Iterate over all fields name
   *
   * @return {Iterator}
   */
  * keys() {
    for (const [name] of this) {
      yield name;
    }
  }

  /**
   * Overwrite all values given name
   *
   * @param   {string}    name      Filed name
   * @param   {string}    value     Field value
   * @param   {string=}   filename  Filename (optional)
   * @return  {undefined}
   */
  set(name, value, filename) {
    ensureArgs(arguments, 2);
    name = String(name);
    const result = [];
    let replaced = false;

    for (let i = 0; i < this._data.length; i++) {
      const match = this._data[i][0] === name;
      if (match) {
        if (!replaced) {
          result[i] = normalizeArgs.apply(null, arguments);
          replaced = true;
        }
      } else {
        result.push(this._data[i]);
      }
    }

    if (!replaced) {
      result.push(normalizeArgs.apply(null, arguments));
    }

    this._data = result;
  }

  /**
   * Iterate over all fields
   *
   * @return {Iterator}
   */
  * values() {
    for (const [, value] of this) {
      yield value;
    }
  }

  /**
   * Return a native (perhaps degraded) FormData with only a `append` method
   * Can throw if it's not supported
   *
   * @return {FormData}
   */
  ['_asNative']() {
    const fd = new _FormData();

    for (const [name, value] of this) {
      fd.append(name, value);
    }

    return fd;
  }

  /**
   * [_blob description]
   *
   * @return {Blob} [description]
   */
  ['_blob']() {
    const boundary = `----formdata-polyfill-${Math.random()}`;
    const chunks = [];

    for (const [name, value] of this) {
      chunks.push(`--${boundary}\r\n`);

      if (value instanceof Blob) {
        chunks.push(
          `Content-Disposition: form-data; name="${name}"; filename="${value.name}"\r\n`,
          `Content-Type: ${value.type || 'application/octet-stream'}\r\n\r\n`,
          value,
          '\r\n',
        );
      } else {
        chunks.push(
          `Content-Disposition: form-data; name="${name}"\r\n\r\n${value}\r\n`,
        );
      }
    }

    chunks.push(`--${boundary}--`);

    return new Blob(chunks, {
      type: `multipart/form-data; boundary=${boundary}`,
    });
  }

  /**
   * The class itself is iterable
   * alias for formdata.entries()
   *
   * @return  {Iterator}
   */
  [Symbol.iterator]() {
    return this.entries();
  }

  /**
   * Create the default string description.
   *
   * @return  {string} [object FormData]
   */
  toString() {
    return '[object FormData]';
  }
}

if (stringTag) {
  /**
   * Create the default string description.
   * It is accessed internally by the Object.prototype.toString().
   */
  FormDataPolyfill.prototype[stringTag] = 'FormData';
}

// Patch xhr's send method to call _blob transparently
if (_send) {
  const { setRequestHeader } = global.XMLHttpRequest.prototype;

  /**
   * @param {string} name
   * @param {string} value
   * @returns {undefined}
   * @see https://xhr.spec.whatwg.org/#dom-xmlhttprequest-setrequestheader
   */
  global.XMLHttpRequest.prototype.setRequestHeader = function (name, value) {
    setRequestHeader.call(this, name, value);
    if (name.toLowerCase() === 'content-type') this._hasContentType = true;
  };

  /**
   * @param {ArrayBuffer|ArrayBufferView|Blob|Document|FormData|string=} data
   * @return {undefined}
   * @see https://xhr.spec.whatwg.org/#the-send()-method
   */
  global.XMLHttpRequest.prototype.send = function (data) {
    // need to patch send b/c old IE don't send blob's type (#44)
    if (data instanceof FormDataPolyfill) {
      const blob = data._blob();
      if (!this._hasContentType) this.setRequestHeader('Content-Type', blob.type);
      _send.call(this, blob);
    } else {
      _send.call(this, data);
    }
  };
}

// Patch fetch's function to call _blob transparently
if (_fetch) {
  const _fetch = global.fetch;

  global.fetch = function (input, init) {
    if (init && init.body && init.body instanceof FormDataPolyfill) {
      init.body = init.body._blob();
    }

    return _fetch.call(this, input, init);
  };
}

// Patch navigator.sendBeacon to use native FormData
if (_sendBeacon) {
  global.navigator.sendBeacon = function (url, data) {
    if (data instanceof FormDataPolyfill) {
      data = data._asNative();
    }
    return _sendBeacon.call(this, url, data);
  };
}

FormDataPolyfill._polyfill = true;

if (typeof Blob !== 'undefined' && (typeof FormData === 'undefined' || !FormData.prototype.keys)) {
  global.FormData = FormDataPolyfill;
}

export default FormDataPolyfill;
