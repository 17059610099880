import Turbolinks from 'turbolinks';
import chatNotificationSound from 'lib/audio';

document.addEventListener('chatMenu-exited', (e) => {
  if (window.location.pathname.includes(e.detail.chatId)) {
    Turbolinks.visit('/chats', { action: 'replace' });
  }
});

document.addEventListener('message-global-received', () => {
  chatNotificationSound();
});
