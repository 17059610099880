import Glide from '@glidejs/glide';

const classes = {
  controls: 'glide__arrows',
  backArrow: 'glide__arrow--left',
  nextArrow: 'glide__arrow--right',
};

function ArrowDisabler(G, Components) {
  return {
    mount() {
      // Only in effect when rewinding is disabled
      if (G.settings.rewind) { return; }

      G.on(['mount.after', 'run'], () => {
        // Filter out arrows_control

        Components.Controls.items.forEach((e) => {
          if (e.className !== classes.controls) { return; }

          // Set left arrow state
          const left = e.querySelector(`.${classes.backArrow}`);
          if (left) {
            if (G.index === 0) {
              left.classList.add('disabled'); // Disable on first slide
            } else {
              left.classList.remove('disabled'); // Enable on other slides
            }
          }

          // Set right arrow state
          const right = e.querySelector(`.${classes.nextArrow}`);
          if (right) {
            // Glide.index is based on the active slide
            // For bound: true, there will be no empty space & the last slide will never become active
            // Hence add perView to correctly calculate the last slide
            const lastSlideIndex = G.settings.bound
              ? G.index + (G.settings.perView - 1)
              : G.index;

            if (lastSlideIndex === Components.Sizes.length - 1) {
              right.classList.add('disabled'); // Disable on last slide
            } else {
              right.classList.remove('disabled'); // Disable on other slides
            }
          }
        });
      });
    },
  };
}

document.addEventListener('turbolinks:load', () => {
  const el = document.querySelector('.js-glide');
  if (!el) { return; }

  new Glide(el, {
    rewind: false,
    animationTimingFunc: 'ease-in-out',
  }).mount({ ArrowDisabler });
});
