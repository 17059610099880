import 'src/newpusher'; // needs to be imported before application_element

import { ApplicationElement } from '@crossiety/design';
import I18n from 'i18n';
import { AppConfig } from 'src/configuration';

// Copy settings from platform to ApplicationElement
ApplicationElement.i18n = I18n;

Object.defineProperty(ApplicationElement.prototype, 'AppConfig', { get() { return AppConfig; } });
