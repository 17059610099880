import ClientSideValidations from '@client-side-validations/client-side-validations';
/**
 * This has to be set to enable custom SimpleForm builder
 * Reuse simple-form validation see {@link https://github.com/DavyJonesLocker/client_side_validations-simple_form/blob/main/src/index.js }
 * */
ClientSideValidations.formBuilders.CustomFormBuilder = {
  add($element, settings, message) {
    this.wrapper(settings.wrapper).add.call(this, $element, settings, message);
  },
  remove($element, settings) {
    this.wrapper(settings.wrapper).remove.call(this, $element, settings);
  },
  wrapper(name) {
    return this.wrappers[name] || this.wrappers.default;
  },

  wrappers: {
    default: {
      add($element, settings, message) {
        const $wrapperElement = $element.closest(`${settings.wrapper_tag}.${settings.wrapper_class.replace(/ /g, '.')}`);
        let $errorElement = $wrapperElement.find(`${settings.error_tag}.${settings.error_class.replace(/ /g, '.')}`);

        if (!$errorElement.length) {
          $errorElement = jQuery(`<${settings.error_tag}>`, { class: settings.error_class, text: message });
          $wrapperElement.append($errorElement);
        }

        $wrapperElement.addClass(settings.wrapper_error_class);
        $errorElement.text(message);
      },

      remove($element, settings) {
        const $wrapperElement = $element.closest(`${settings.wrapper_tag}.${settings.wrapper_class.replace(/ /g, '.')}.${settings.wrapper_error_class}`);
        const $errorElement = $wrapperElement.find(`${settings.error_tag}.${settings.error_class.replace(/ /g, '.')}`);

        $wrapperElement.removeClass(settings.wrapper_error_class);
        $errorElement.remove();
      },
    },
  },
};
