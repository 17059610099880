import _throttle from 'lodash/throttle';
import { isItemInViewPort, scrollDirection } from 'src/lib/utils';
import triggerEvent from 'src/lib/utils/trigger_event';

const loadMore = (trigger, event) => {
  const $this = $(trigger);
  const $container = $this.closest('[data-load-more]');
  const $body = $('body');

  if ($body.hasClass('load-more-active')) { return; }

  $body.addClass('load-more-active');

  $.ajax({
    url: $this.attr('href'),
    headers: {
      // 'X-Partial-Selector': '[data-load-more]',
      'X-No-Layout': 1,
    },
  }).done((res) => {
    $('.load-more-container', $container).remove();
    const $res = $('<div />').html(res);
    $res.find('.js-load-before, .panel-box-msg').remove();
    $res.find('.js-xs-list > [slot]').remove(); // only for xs_smart_list rails helper to not duplicate slots

    const html = $res.find('[data-load-more]').length ? $res.find('[data-load-more]').html() : res;
    $container.append(html);

    $body.removeClass('load-more-active');
    if (event === 'infiniteScroll') {
      setTimeout(() => { // prevent infiniteScroll() to trigger multiple times because of mouse accelerator
        isItemInViewPort($(trigger)) && loadMore(trigger, 'infiniteScroll'); // load next page if loader is in viewport
      }, 1000);
    }
    triggerEvent(document, 'load_more:done');
  });
};

const infiniteScroll = () => {
  const el = '.js-load-more';
  const bottomOffset = 1600;

  // Defer initial load
  setTimeout(() => {
    isItemInViewPort($(el)) && loadMore(el, 'infiniteScroll'); // initial load next page if loader is in viewport
  }, 0);

  //  Bind loader on scroll event
  $(window).on('scroll.load_more', _throttle(() => {
    if (!$(el).length) { return; }

    if (scrollDirection.getScrollDirection() === 'down') { // If scroll down direction
      setTimeout(() => { // To get $(window).scrollTop() because of scroll duration
        if (($(window).scrollTop() >= $(document).height() - $(window).height() - bottomOffset) || isItemInViewPort($(el))) {
          loadMore(el, 'infiniteScroll');
        }
      }, 250);
    }
  }, 500));
};

$(document).on('click', '.js-load-more', (e) => {
  e.preventDefault();
  loadMore('.js-load-more', 'click');
});

$(document).on('click', '.js-load-before', function onClickJsLoadBefore(e) {
  e.preventDefault();
  const $this = $(this);
  const $currentLink = $this.closest('.js-load-before');
  const $container = $this.closest('[data-load-more]');

  $('.js-event-cards-count .badge').fadeOut();
  $('.js-community-cards-count .badge').fadeOut();
  $('.js-marketplace-cards-count .badge').fadeOut();

  $.ajax({
    url: this.href,
    headers: {
      'X-No-Layout': 1,
    },
  }).done((res) => {
    $currentLink.remove();
    $('.injected', $container).remove();
    const $res = $('<div />').html(res);
    $res.find('.js-load-more').remove();
    const html = $res.find('[data-load-more]').length ? $res.find('[data-load-more]').html() : res;
    $container.prepend(html);
  });
  triggerEvent(document, 'load_before:done');
});

const triggerScroll = () => {
  $(window).off('.load_more');
  scrollDirection.setInitialScrollPosition();
  infiniteScroll();
};

document.addEventListener('turbolinks:load', () => {
  triggerScroll();
});

document.addEventListener('live:done', () => {
  triggerScroll();
});
