import 'custom-event-polyfill';

/* Code takken from rails-ujs library */

if (typeof CustomEvent !== 'function') {
  window.CustomEvent = (event, params) => {
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  };

  CustomEvent.prototype = window.Event.prototype;
  const originalPreventDefault = CustomEvent.prototype.preventDefault;

  CustomEvent.prototype.preventDefault = function preventDefault() {
    const result = originalPreventDefault.call(this);

    if (this.cancelable && !this.defaultPrevented) {
      Object.defineProperty(this, 'defaultPrevented', {
        get() {
          return true;
        },
      });
    }
    return result;
  };
}
