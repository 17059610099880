import pusher from 'src/newpusher';
import { NetworkNotice } from '@crossiety/design';

function update() {
  NetworkNotice.setOnline();
}

document.addEventListener('sync', update);
document.addEventListener('ajax:success', update);
document.addEventListener('turbolinks:load', update);
pusher.bind_global((eventName) => {
  if (eventName === 'pusher:pong') update();
});
