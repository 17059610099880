import Rails from 'rails-ujs';

document.addEventListener('turbolinks:request-start', (event) => {
  const { xhr } = event.data;
  xhr.setRequestHeader('X-Turbolinks-Nonce', Rails.cspNonce());
});

document.addEventListener('turbolinks:before-cache', () => {
  $('script[nonce]').each((index, element) => {
    $(element).attr('nonce', element.nonce);
  });
});
