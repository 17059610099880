import $ from 'jquery';

$(document)
  .on('change', 'input[type="file"]', function onChangeFileUpload() {
    const file = this.files[0];
    const $this = $(this);

    // IE11 triggers change on set and clear so we need return if no file
    if (!file) { return; }

    $this.siblings('.js-file-name').html(file.name);
    $this.siblings('.js-clear-file').show();
    $this.closest('.file-upload-wrapper').addClass('file-attached');
    $this.trigger('elementresize');
  })

  // RESET FILE INPUT
  .on('click', '.js-clear-file', function onClickFileUpload() {
    const $this = $(this);

    $this.siblings('input[type="file"]').val('').trigger('elementresize');
    $this.siblings('.js-file-name').html('');
    $this.closest('.file-upload-wrapper').removeClass('file-attached');
    $this.hide();
  })

  .on('reset', 'form', function onResetFileUpload() {
    $('.js-clear-file', this).click();
  })

  // SHOULD DELETE FILE
  .on('click', '.js-delete-file-btn', function onClickFileUploadDelete(e) {
    e.preventDefault();

    $('.js-should-delete-file', $(this).closest('.file-upload-wrapper')).val('1');
    $(this).closest('.file-preview').remove();
  });
