// *** INIT *** //
// set target element(s) to hide with data attribute data-visibility-target
// <input type="checkbox" data-visibility-target="datetime-time" />
// and reference(s) with data attribute data-visibility-id
// <div data-visibility-id="datetime-time"></div>

// *** TOGGLE TEXT *** //
// visibility_text_show = "String"
// visibility_text_hide = "String"

// *** STATUS *** //
// visibility_status = boolean => default: true

function elementsByVisibilityTarget(target) {
  return document.querySelectorAll(`[data-visibility-id*="${target}"]`);
}

$(document)
  .on('change', '[data-visibility-target], .js-toggle-visibility', (e) => {
    let { visibilityTarget } = e.currentTarget.dataset;

    if (e.currentTarget.tagName === 'SELECT') {
      // Remove visibility for all elements inside select option that match 'data-visibility-target'

      const { options, selectedIndex } = e.currentTarget;
      const currentOption = options[selectedIndex];
      visibilityTarget = currentOption.dataset.visibilityTarget;

      // First hide all
      document.querySelectorAll('[data-visibility-id]').forEach(el => el.classList.add('hidden'));

      // Then show only matched
      elementsByVisibilityTarget(visibilityTarget).forEach(d => d.classList.remove('hidden'));
    } else {
      const isChecked = e.currentTarget.checked;
      elementsByVisibilityTarget(visibilityTarget)
        .forEach(d => d.classList[isChecked ? 'remove' : 'add']('hidden'));
    }
  });

// This is only used for organize card
// app/views/public/cards/organize_cards/_organize_item_fields.html.erb
function toggleTextOn(button) {
  const input = document.getElementById(button.getAttribute('for'));

  const { visibilityStatus, visibilityTextShow, visibilityTextHide } = input.dataset;

  const isVisible = visibilityStatus === 'true';
  const text = isVisible ? visibilityTextShow : visibilityTextHide;

  input.dataset.visibilityStatus = String(!isVisible);

  button.innerHTML = text;
}

$(document)
  .on('click', '.organize_card_items_goal_required label', (e) => {
    const button = e.currentTarget;
    const amount = button.closest('.nested-fields').querySelector('.nested-fields-goal');
    amount.classList.toggle('hidden');

    toggleTextOn(button);
  });
