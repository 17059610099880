import $ from 'jquery';
import 'bootstrap-datepicker';

// Embed content from https://github.com/uxsolutions/bootstrap-datepicker/blob/master/js/locales/bootstrap-datepicker.de.js
$.fn.datepicker.dates.de = {
  days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
  daysShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
  daysMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
  monthsShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
  today: 'Heute',
  monthsTitle: 'Monate',
  clear: 'Löschen',
  weekStart: 1,
  format: 'dd.mm.yyyy',
};

// Application setup
$.fn.datepicker.defaults.language = 'de';
$.fn.datepicker.dates.de.format = 'dd/mm/yyyy';
$.fn.datepicker.defaults.weekStart = 1;
$.fn.datepicker.defaults.todayHighlight = true;
