import I18n from 'i18n';
import { reloadElement } from 'src/utils';
import { CommonActions } from '@crossiety/design';
import { on } from 'src/lib/utils';

// navbar - unread web notifications count badge
const lastCounts = {
  web_notifications: 0,
};

let pageTitle;

function clearNotificationsList(selector) {
  const $el = $(selector);
  $el.removeClass('open');
  $('.dropdown-menu .ajax-content', $el).empty();
}

function updateDocumentTitle() {
  const chatNotificationCount = window.computedChatsStore?.value?.unreadCounters?.allWithoutMuted;
  const totalCount = chatNotificationCount + lastCounts.web_notifications;

  if (App.notifications_count !== totalCount) {
    App.notifications_count = totalCount;

    document.dispatchEvent(
      new CustomEvent('notifications:total_count:update', {
        bubbles: false,
        cancelable: true,
        detail: {
          totalCount,
        },
      }),
    );

    document.title = App.notifications_count > 0 ? (`(${App.notifications_count}) ${pageTitle}`) : pageTitle;
  }
}

function updateBadgeCount(selector, currentCount, opts = { animate: true }) {
  const $badge = $(selector);

  if (opts.animate) {
    currentCount > 0 ? $badge.fadeIn() : $badge.fadeOut();
  } else {
    currentCount > 0 ? $badge.show() : $badge.hide();
  }

  $badge.text(currentCount);
}

$(document)
  .on('turbolinks:render', () => {
    updateBadgeCount('.js-unread-web-notifications-count .badge', lastCounts.web_notifications, { animate: false });
  })

  .on('turbolinks:load', () => {
    pageTitle = document.title.replace(/\(\d+\)\s*/, '');
  })

  .on('sync', (e) => {
    const data = e.detail;
    if (data.unread_web_notifications_count !== lastCounts.web_notifications) {
      updateBadgeCount('.js-unread-web-notifications-count .badge', data.unread_web_notifications_count);
      clearNotificationsList('.js-web-notifications-dropdown');
      reloadElement('.js-notifications');
    }

    lastCounts.web_notifications = data.unread_web_notifications_count || 0;

    updateDocumentTitle();
  })

  .on('visibility', () => {
    updateDocumentTitle();
  })

  .on('message-global-received', () => {
    updateDocumentTitle();
  })

  .on('show.bs.dropdown', '.notifications-dropdown', (e) => {
    const self = e.currentTarget;
    if (!self.dataset.notificationsUrl) { return; }
    if (!$('.dropdown-menu .ajax-content', self).html()) {
      self.classList.add('loading');
      $.get(self.dataset.notificationsUrl, (data) => {
        $('.dropdown-menu .ajax-content', self).html(data);
        self.classList.remove('loading');
      });
    }
  });

on(document, 'click', '.js-read-all-web-notifications', async (e) => {
  const commonActions = new CommonActions(e);

  try {
    await commonActions.post(e.dataset.url);
    const unreadNotifications = document.querySelectorAll('.js-notifications xs-notification:not([isRead]');
    const dropdownNotifications = document.querySelector('.js-web-notifications-dropdown .dropdown-menu .ajax-content');
    const message = I18n.t('profile.notifications.no_new_notifications');

    lastCounts.web_notifications = 0;
    updateBadgeCount('.js-unread-web-notifications-count .badge', lastCounts.web_notifications);
    unreadNotifications.forEach(item => item.isRead = true);

    if (dropdownNotifications.textContent) {
      dropdownNotifications.querySelector('.header').innerHTML = `<span>${message}</span>`;
      dropdownNotifications.querySelector('xs-list').remove();
    }
  } catch {
    commonActions.displaySnackBarError();
  }
});
