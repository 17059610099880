import Rails from 'rails-ujs';

const clearForms = (e) => {
  e.preventDefault();

  const form = document.querySelector('.js-filter-form');

  // Clear inputs
  [...form.elements].forEach((input) => {
    input.value = '';
  });

  Rails.fire(form, 'submit');
};

$(document)
  .on('click', '.js-filters-clear', (e) => { clearForms(e); })

  // Clear input value
  .on('click', '.js-input-clear', (e) => {
    e.preventDefault();

    const { currentTarget: button } = e;
    const input = button.previousSibling;

    input.value = '';
    input.focus();
    Rails.fire(input, 'input');
  });
