let submenuScrollPosition;

function sumenuScroll(restore) {
  const s = document.querySelector('.js-submenu');
  if (!s) { return; }

  if (restore) { s.scrollLeft = submenuScrollPosition; }
  submenuScrollPosition = s.scrollLeft;
}

document.addEventListener('turbolinks:before-cache', () => {
  sumenuScroll();
});

document.addEventListener('turbolinks:render', () => {
  sumenuScroll(true);
});

document.addEventListener('turbolinks:load', () => {
  // Add body class if submenu is present
  $('.js-submenu').length && $('body').addClass('has-submenu');

  //  Scroll horizontal navigation active item into the visible area of the browser window.
  const activeHorizontalItem = document.querySelector('.js-submenu .active');
  activeHorizontalItem && activeHorizontalItem.scrollIntoView(false); // 'false' parameter is a fix for older browsers (Android 5.0)
});
