import _padStart from 'lodash/padStart';

const elementToDateArray = el => el.value.split('-');
const elementToTimeArray = el => el.value.split(':');

const parseNativeDateFromElements = (dateEl, timeEl) => {
  const [year, month, day] = elementToDateArray(dateEl);
  const [hours, minutes] = elementToTimeArray(timeEl);
  return new Date(year, month - 1, day, hours, minutes);
};

const parsePickerDateFromElements = (dateEl, hourEl, minEl) => {
  const [day, month, year] = dateEl.value.split('/').map(n => parseInt(n, 10));
  const hours = hourEl.value;
  const minutes = minEl.value;
  return new Date(year, month - 1, day, hours, minutes);
};

const addHourToDate = (date) => {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + 1);
  return newDate;
};

const addDaysToDate = (date, days) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

const dateToString = (d) => {
  const year = d.getFullYear();
  const month = _padStart(d.getMonth() + 1, 2, 0);
  const day = _padStart(d.getDate(), 2, 0);
  const hours = _padStart(d.getHours(), 2, 0);
  const minutes = _padStart(d.getMinutes(), 2, 0);

  return {
    dateNative: `${year}-${month}-${day}`,
    datePicker: `${day}/${month}/${year}`,
    time: `${hours}:${minutes}`,
    year,
    month,
    day,
    hours,
    minutes,
  };
};

const daysDiff = (startDate, endDate) => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  return Math.round((endDate - startDate) / millisecondsPerDay);
};

const setupUI = $c => ({
  // Shared
  startDate: $c.querySelector('#event_card_starts_at_date'),
  endDate: $c.querySelector('#event_card_ends_at_date'),

  // Native
  startTime: $c.querySelector('#event_card_starts_at_time'),
  endTime: $c.querySelector('#event_card_ends_at_time'),

  // Picker
  endHour: $c.querySelector('#event_card_ends_at_hour'),
  endMinutes: $c.querySelector('#event_card_ends_at_min'),

  startHour: $c.querySelector('#event_card_starts_at_hour'),
  startMinutes: $c.querySelector('#event_card_starts_at_min'),
});

const setEndDate = (newEndDate, isNative, ui) => {
  const { dateNative, time, datePicker, hours, minutes } = dateToString(newEndDate);

  if (isNative) {
    ui.endDate.value = dateNative;
    ui.endTime.value = time;
  } else {
    ui.endDate.value = datePicker;
    ui.endHour.value = hours;
    ui.endMinutes.value = minutes;
  }

  return {
    newEndDate,
    ui,
  };
};

const smartDatePicker = (container) => {
  if (!container) { return false; }

  const isNative = container.classList.contains('native');
  const ui = setupUI(container);

  let startDate;
  let endDate;

  if (isNative) {
    startDate = parseNativeDateFromElements(ui.startDate, ui.startTime);
    endDate = parseNativeDateFromElements(ui.endDate, ui.endTime);
  } else {
    startDate = parsePickerDateFromElements(ui.startDate, ui.startHour, ui.startMinutes);
    endDate = parsePickerDateFromElements(ui.endDate, ui.endHour, ui.endMinutes);
  }

  if (startDate < endDate) {
    if (daysDiff(startDate, endDate) <= 30) { return false; }

    // Limit event duration to 30 days
    const newEndDate = addDaysToDate(startDate, 30);
    return setEndDate(newEndDate, isNative, ui);
  }

  // Set endDate to be equal to startDate
  const newEndDate = addHourToDate(startDate);
  return setEndDate(newEndDate, isNative, ui);
};

$(document).on('change', '#event_card_starts_at_date, #event_card_starts_at_hour, #event_card_starts_at_min', (e) => {
  smartDatePicker(e.target.closest('.js-set-date'));
});

// Testing api
export const __private__ = {
  elementToDateArray,
  elementToTimeArray,
  parseNativeDateFromElements,
  parsePickerDateFromElements,
  addHourToDate,
  dateToString,
};

export default smartDatePicker;
