import '@client-side-validations/client-side-validations';
import '@client-side-validations/simple-form/src';
import 'lib/custom_simple_form_validations';

// Because turbolinks is not attached to window rails.validations don't know how
// to setup event handler so we need to do it ourselfs
document.addEventListener('turbolinks:load', () => {
  $(window.ClientSideValidations.selectors.forms).enableClientSideValidations();
});

document.addEventListener('inlineForm-change', () => {
  $(window.ClientSideValidations.selectors.forms).enableClientSideValidations();
});
