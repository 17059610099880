import $ from 'jquery';

$.fn.scrollToEnd = function scrollToEnd() {
  return $(this).each((i, el) => el.scrollTop = el.scrollHeight);
};

$.fn.scrollToElement = function scrollToElement(target, offset = 0, speed = 300) {
  const $target = $(target);
  if (!$target.length) { return this; }
  const $this = $(this);

  const thisOffset = $this.offset();
  if (!thisOffset) { return this; }

  const scrollTop = $this.scrollTop();
  const offsetTop = thisOffset.top;

  const targetOffsetTop = $target.offset().top;

  const newScrollTop = scrollTop - offsetTop + targetOffsetTop - offset;

  return $(this).animate({ scrollTop: newScrollTop }, speed);
};

$.fn.scrollEndOffset = function scrollEndOffset() {
  return $(this).prop('scrollHeight') - $(this).scrollTop();
};

$.fn.pluck = function pluck(attr, cb) {
  return $(this).map((i, el) => {
    const val = el.dataset[attr];
    return cb ? cb(val) : val;
  }).get();
};
