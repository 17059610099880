// Mostly IE11 classList polyfill, and some Android 4.x, 5.x

const copyProperty = (prop, { source, to }) => {
  const desc = Object.getOwnPropertyDescriptor(source, prop);
  Object.defineProperty(to, prop, { ...desc });
};

const el = document.createElement('a');
const { classList: { add, remove, constructor: { prototype } } } = el;

try { el.replace('a', 'b'); } catch (e) {
  prototype.replace = function replace(oldToken, newToken) {
    this.remove(oldToken);
    return this.add(newToken);
  };
}

try { el.add('a', 'b'); } catch (e) {
  prototype.add = function enhancedAdd(...tokens) {
    tokens.forEach(token => add.call(this, token));
  };
}

try { el.remove('a', 'b'); } catch (e) {
  prototype.remove = function enhancedRemove(...tokens) {
    tokens.forEach(token => remove.call(this, token));
  };
}

if (!('classList' in Element.prototype)) {
  copyProperty('classList', { source: HTMLElement.prototype, to: Element.prototype });
}
