import Snack from '@effectivastudio/snackbar';
import I18n from 'i18n';

$(document)
  .on('group_member.update.fm', (e, data) => {
    const roleName = I18n.t(`group_member.role.${data.params.role_name.toLowerCase()}.name`);
    document.querySelector(`[data-id="${data.id}"]`).groupRole = roleName;

    Snack({ text: I18n.t('group_member.modal.change_role.confirmation_msg') });
  });
