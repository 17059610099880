import * as Sentry from '@sentry/browser';

App.config.env.SENTRY_ON && Sentry.init({
  dsn: App.config.env.SENTRY_DSN,
  environment: App.config.env.RAILS_ENV,
  release: App.config.env.SENTRY_RELEASE,
  // debug: true, // turn this on for debugging...
  ignoreErrors: [/ResizeObserver loop completed with undelivered notifications/],
  tracesSampleRate: 0.9,
  integrations: [
    // Attaches global handlers to capture uncaught exceptions and unhandled rejections.
    Sentry.globalHandlersIntegration({ onunhandledrejection: false }),
    // Send console.error to Sentry
    Sentry.captureConsoleIntegration({ levels: ['error'] }),
  ],
  beforeSend(event) {
    const appVersion = App.config.runtime.mobile_app_version;

    Sentry.setTags({
      ...(appVersion && { appVersion }),
    });

    Sentry.setUser({ id: App.config.user.id });

    return event;
  },
});
