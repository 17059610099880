// Share pop-ups

const SHARES = {
  twitter: [700, 300],
  facebook: [500, 500],
  other: [500, 500],
};

const socialShareLinkPopup = (e) => {
  const el = e.target.closest('.js-social-share li a');
  if (!el) { return; }

  e.preventDefault();

  const popup = el.dataset.share;
  const link = el.href;
  const [width, height] = SHARES[popup] || SHARES.other;

  window.open(link, popup, `width=${width}, height=${height}`);
};

document.addEventListener('click', socialShareLinkPopup);
