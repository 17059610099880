import Rails from 'rails-ujs';

if (!App.config.runtime.isMobileDevice) {
  $(document).on('keypress', '.js-auto-size', (e) => {
    if (e.which === 13 && !e.shiftKey) {
      e.preventDefault();
      Rails.fire(e.target.form, 'submit');
    }
  });
}

$(document)
  .on('focus', '.js-auto-size', function onFocusAutoSize() {
    if (!this.clone) {
      this.clone = $('<textarea />')
        .css({ width: this.clientWidth, height: this.clientHeight })
        .attr('tabindex', -1)
        .addClass(this.className.replace('js-auto-size', ''))
        .get(0);

      this.cloneWrapper = $('<div />', { style: 'position:absolute; height:10px; width:10px; overflow:hidden; z-index:-10;' })
        .append(this.clone);
      $(this).after(this.cloneWrapper);
      this.clone.baseScrollHeight = this.clone.scrollHeight;
    }
  })

  .on('input', '.js-auto-size', function onInputAutoSize() {
    if (!this.clone) { return; }
    this.clone.value = this.value;
    this.rows = Math.min(6, Math.round(this.clone.scrollHeight / this.clone.baseScrollHeight));
  })

  .on('keyup', '.js-auto-size', function onKeyupAutoSize() {
    const $this = $(this);
    const $el = $this.closest('form');

    $this.val() ? $el.addClass('active') : $el.removeClass('active');
  });
