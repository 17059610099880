import I18n from 'i18n';
import { reloadElement } from 'src/utils';

function toggleSubscribeButton(e) {
  const el = document.querySelector('.js-toggle-subscribe button');

  if (!el) { return; }

  const elSpan = el.querySelector('span');
  const elIcon = el.querySelector('ef-icon');
  const isSubscribed = el.classList.contains('btn-primary');

  el.classList.toggle('btn-primary');
  el.classList.toggle('btn-secondary');
  elIcon.name = isSubscribed ? 'bell-slash' : 'bell';
  elSpan.innerText = I18n.t(isSubscribed ? 'group.unsubscribe.title' : 'group.subscribe.title');

  if (e.currentTarget.tagName === 'FORM') {
    const el2 = document.querySelector('xs-group-menu');
    el2.subscribeValue = isSubscribed;
  }
}

$(document)
  .on('ajax:success:inline', '.js-description-update', function onAjaxSuccessDescriptionUpdate() {
    $(this).trigger('updateGroupDescription:done');
  })
  .on('ajax:error:inline', '.js-description-update', function onAjaxErrorDesciptionUpdate() {
    $(this).trigger('updateGroupDescription:fail');
  });

// Accept / Reject group request to join
// Homepage + Group invites
$(document)
  .on('ajax:beforeSend', '.js-accept-request', function onBeforeSendAcceptRequest() {
    $(this).closest('[data-hide-id]').remove();
  })
  .on('ajax:success', '.js-accept-request', function onSuccessAcceptRequest() {
    $(this).closest('.member').promise().done(() => {
      reloadElement('.js-group-invites');
    });
  });

$(document).on('ajax:success', '.js-toggle-subscribe', toggleSubscribeButton);
$(document).on('group-subscribe', toggleSubscribeButton);
