import { AppConfig } from 'src/configuration';
import Pusher from 'pusher-js';
import Rails from 'rails-ujs';

const { RAILS_ENV, PUSHER_JS_LOGGING } = AppConfig.env;
const isLocal = RAILS_ENV === 'development' || RAILS_ENV === 'test';
const { PUSHER_KEY, PUSHER_HOST, PUSHER_PORT = 6001 } = AppConfig.env;

if (PUSHER_JS_LOGGING) {
  Pusher.logToConsole = true;
}

/**
 * Pusher wrapper that only connects if user is logged in
 *
 * This way:
 *  - we can avoid having pusher connected when user is not logged in
 *  - we can initialize pusher before user is logged in and exposing it as global variable for other modules (eg. chat)
 *  - and it is still instance of Pusher so we can use it as usual
 *
 */
class AppPusher extends Pusher {
  // https://github.com/pusher/pusher-js/blob/bfa444a7d5450d5c3a7f87be178a7e533faf7644/src/core/pusher.ts#L152
  connect() {
    if (!AppConfig.userIsLoggedIn) return;

    super.connect();
  }
}

// We don't have pusher in admin
const pusher = PUSHER_KEY && new AppPusher(PUSHER_KEY, {
  cluster: 'eu',
  encrypted: true,
  forceTLS: !isLocal,
  wsHost: PUSHER_HOST,
  wsPort: isLocal ? PUSHER_PORT : null,
  enabledTransports: ['ws', 'wss'],
  channelAuthorization: {
    headers: {
      'X-CSRF-Token': Rails.csrfToken(),
    },
  },
});

if (pusher) {
  // Increase number of connection retries
  pusher.connection.strategy.transports.ws.transport.manager.livesLeft = 9999;

  // Error catching
  pusher.connection.bind('error', (errorObject) => {
    console.warn(errorObject);
  });

  pusher.connection.bind('state_change', (states) => {
    document.dispatchEvent(new Event(`pusher:${states.current === 'connected' ? 'connected' : 'disconnected'}`));
  });

  document.addEventListener('app:config:updated', () => {
    AppConfig.userIsLoggedIn ? pusher.connect() : pusher.disconnect();
  });
}

window.pusher = pusher;

export default pusher;
