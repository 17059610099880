function formDataValues(form) {
  const data = new FormData(form);
  const out = [];
  data.forEach((item) => {
    out.push(item);
  });
  return out;
}

document.addEventListener('turbolinks:load', () => {
  $('[data-edit]').each((i, element) => {
    const $c = $(element);
    const d = $c.data();

    if (d.inlineFormed) { return; }

    $c.data('inlineFormed', true);

    let $formRequest = $.ajax({ url: d.edit, headers: { 'X-Inline-Form': 1 } });
    let formValues = '';
    let showHTML = $c.html();
    let open = false;

    let $handlerElement = $c.closest('.inline_form_group');
    $handlerElement = $handlerElement.length ? $handlerElement : $c;

    const close = function close() {
      $c.html(showHTML);
      $handlerElement.removeClass('edit loading');
      open = false;
    };

    $handlerElement.on('click', '.js-inline-edit', (e) => {
      e.preventDefault();
      if (open) {
        close();
        return;
      }
      $formRequest.state() === 'pending' && $handlerElement.addClass('loading');
      $formRequest.done((response) => {
        $c.html(response);
        const $form = $c.find('form');
        $form.enableClientSideValidations();
        formValues = formDataValues($form.get(0));
        $form.find('[autofocus]').focus();
        $handlerElement.addClass('edit');
        $handlerElement.removeClass('loading');
        open = true;
      });
    });

    $handlerElement.on('click', '.js-inline-cancel', (e) => {
      e.preventDefault();
      close();
    });

    if (d.preventCleanup === undefined) {
      $c.on('cleanup.turbolinks', () => close());
    }

    $c.on('reload', () => {
      $handlerElement.addClass('loading');
      $formRequest = $.ajax({ url: d.edit, headers: { 'X-Inline-Form': 1 } });
      $.ajax({ url: d.show, headers: { 'X-Inline-Form': 1 } }).done((response) => {
        showHTML = response;
        close();
      });
    });

    $c.on('ajax:before', (e) => {
      if (e.target.className.indexOf('js-inline') < 0) { return; }
      $(e.target).trigger(`${e.type}:inline`);

      const newFormValues = formDataValues($c.find('form').get(0));
      const hasEmptyRequiredFields = $c.find('[required]').filter(function filterRequiredFields() {
        return $(this).val().trim() === '';
      }).length;

      if (!hasEmptyRequiredFields && newFormValues === formValues) {
        e.preventDefault();
        close();
      }
    });

    $c.on('ajax:beforeSend', (e) => {
      if (e.target.className.indexOf('js-inline') < 0) { return; }
      $(e.target).trigger(`${e.type}:inline`);

      $handlerElement.addClass('loading');
      e.detail[0].setRequestHeader('X-Inline-Form', 1);
    });

    $c.on('ajax:success', (e) => {
      if (e.target.className.indexOf('js-inline') < 0) { return; }
      const inlineId = e.target.dataset.inline;
      const xhr = e.detail[2];
      const { response } = xhr;
      $handlerElement.removeClass('loading');
      const $tmp = $('<div />').html(response);
      if (!$tmp.find('.has-error').length) {
        showHTML = response;
        close();
        $formRequest = $.ajax({ url: d.edit, headers: { 'X-Inline-Form': 1 } });
        $c.trigger(`${e.type}:inline`, e);
        inlineId && $c.trigger(`${e.type}:inline:${inlineId}`, e);
        return;
      }
      $c.html(response);
    });
  });
});
