import morphdom from 'morphdom';

window.App = window.App || { mutation_observers: [] };

export const reloadElement = (selector) => {
  const element = document.querySelector(selector);
  if (!element) { return; }

  const { pathname, search } = window.location;
  let newSearch;

  if (!search) {
    newSearch = `${search}?x-partial-selector=${selector}`;
  } else {
    newSearch = `${search}&x-partial-selector=${selector}`;
  }

  const url = pathname + newSearch;

  $.ajax({
    url,
    headers: {
      'X-Inline-Form': 1,
      'X-Partial-Selector': selector,
    },
  }).done((response) => {
    morphdom(element, response);
  });
};

window.reloadElement = reloadElement;

export const $d = (selector, id) => $(`${selector}[data-id="${id}"]`);
