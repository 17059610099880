import { timestampToDate } from 'src/lib/pure_utils';
import I18n from 'i18n';

export const modifyEventDate = (el) => {
  if (el === undefined || !el.classList.contains('js-event-date')) { return; }

  const startDate = timestampToDate(el.dataset.startDate);
  const endDate = timestampToDate(el.dataset.endDate);
  const currentDate = timestampToDate(Date.now());

  // Return if Event didin't started yet or already ended
  if (endDate <= currentDate || startDate > currentDate) { return; }

  // Show today's date on event overview, not Event end date
  el.querySelector('.day').innerHTML = currentDate.getDate();
  el.querySelector('.month').innerHTML = I18n.t(`date.abbr_month_names.${currentDate.getMonth() + 1}`);
  el.querySelector('.year').innerHTML = currentDate.getFullYear();
};

export default modifyEventDate;
