/*  eslint-disable max-classes-per-file */

import { AppConfig } from 'src/configuration';
import { encodeData, decodeData } from 'lib/base64';
import Gallery from '@effectivastudio/image_gallery';
import Turbolinks from 'turbolinks';
import { goBackOrDefault } from './new_common_interface';

const { location } = window;

const EMBEDDED_TEMPLATE_BODY_CLASS = 'ct-body';

function isEmbeddedPage() {
  return document.body.classList.contains(EMBEDDED_TEMPLATE_BODY_CLASS);
}

// History class
export default class VirtualPage {
  constructor(name) {
    this.name = name;

    // eslint-disable-next-line prefer-destructuring
    this.encodedData = decodeURIComponent(location.hash).split(
      `#/${this.name}/`,
    )[1];
    this.state = this.loadCurrentState();
  }

  get isValid() {
    return !!this.state;
  }

  loadCurrentState() {
    return this.encodedData && decodeData(this.encodedData);
  }

  get hash() {
    const { name, state } = this;
    const version = AppConfig.env.SENTRY_RELEASE?.slice(-4) || AppConfig.env.ASSETS_VERSION;
    return `/minimal/${version}#/${name}/${encodeData(state)}`;
  }
}

function openImageGallery(pageState, { goBack = true } = {}) {
  const data = pageState.galleryUrl ? pageState : { json: [pageState] };

  new Gallery(data, {
    onHide: () => goBack && goBackOrDefault(data.referrer),
  }).load();

  // do the same thing for arrow-left and arrow-right when platform supports them
  document.querySelector('.iv-fullscreen-download').innerHTML = '<i class="icon-cloud-download"></i>';
  document.querySelector('.iv-fullscreen-close').innerHTML = '<i class="icon-close"></i>';
}

let page;
$(document)
  // eslint-disable-next-line consistent-return
  .on('turbolinks:load', () => {
    if (isEmbeddedPage()) {
      return null;
    }

    page = new VirtualPage('image');

    if (page.isValid) {
      return openImageGallery(page.state);
    }

    if (location.href.includes('/minimal')) {
      return Turbolinks.visit('/');
    }
  })

  .on('click', '.js-img-zoom, .js-img-gallery', (e) => {
    if (isEmbeddedPage()) {
      return;
    }

    e.preventDefault();
    page.state = { ...e.currentTarget.dataset, referrer: location.href };
    Turbolinks.visit(page.hash);
  });

document.addEventListener('turbolinks:before-cache', () => {
  Gallery.destroyAll();
});

document.addEventListener('message-image-clicked', (e) => {
  if (isEmbeddedPage()) {
    return;
  }

  e.preventDefault();
  page.state = { ...e.detail.realTarget.dataset, referrer: location.href };

  openImageGallery(page.state, { goBack: false });
  // Turbolinks.visit(page.hash);
});
