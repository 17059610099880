// Setup mutation observers

window.App ||= {};
window.App.mutation_observers = [];

const ObserverKlass = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

const observer = new ObserverKlass(((mutations) => {
  const appObserversLength = App.mutation_observers.length;

  mutations.forEach((mutation) => {
    mutation.addedNodes.forEach((n) => {
      // Only elements
      if (n.nodeType !== 1) { return; }

      if (appObserversLength) {
        for (let i = 0; i < appObserversLength; i++) {
          App.mutation_observers[i](n);
        }
      }
    });
  });
}));

document.addEventListener('DOMContentLoaded', () => {
  observer.observe(document, {
    childList: true,
    subtree: true,
    characterData: false,
  });
});
