/* eslint no-underscore-dangle: ["error", { "allow": ["_paq"] }] */

// CSP
// "default-src 'self';
// connect-src https://matomo.example.com;
// script-src 'self' https://matomo.example.com;
// img-src 'self' https://matomo.example.com;
// style-src 'self';
// frame-ancestors 'self';
// frame-src 'self';"

// This file needs to go before `analytics_page_tracking`
/* tracker methods like "setCustomDimension" should be called before "trackPageView" */

// const siteId = App.config.env.MATOMO_APP_ID;
// const trackingApiUrl = 'https://matomo.crossiety.app/matomo.php';

// Send Matomo a new event when navigating to a new page using Turbolinks
// (see https://developer.matomo.org/guides/spa-tracking)

import { AppConfig } from 'src/configuration';

const siteId = AppConfig.env.MATOMO_APP_ID;
const trackingApiUrl = 'https://matomo.crossiety.app/matomo.php';
const { isMobileApp, countryId, townshipId, townshipIsActive } = AppConfig.runtime;

function track(name, ...values) {
  window._paq.push([name, ...values]);
}

function setCustomDimension({ id, value }) {
  if (value === null || typeof value === 'undefined') {
    return;
  }

  track('setCustomDimension', id, value);
}

function manuallyConfigureMatomo() {
  window.Matomo.addTracker();

  if (siteId) {
    track('setTrackerUrl', trackingApiUrl);
    track('setSiteId', siteId);
  }
}

const matomoPromise = new Promise((resolve, reject) => {
  if (!siteId) resolve(false);

  let elapsed = 0;
  const delay = 200;

  const checker = setInterval(() => {
    if (elapsed > 5000) reject(new Error('Matomo initialization timeout'));
    if (window.Matomo?.initialized) {
      manuallyConfigureMatomo();
      clearInterval(checker);
      resolve(true);
    }
    elapsed += delay;
  }, delay);
});

let previousPageUrl = null;

function trackPage(event) {
  const { timing } = event.data || {};

  // First dimension ---------------------------------------------------------------------------------
  setCustomDimension({
    id: 1,
    value: isMobileApp ? 'MobileApp' : 'BrowserApp',
  });

  // Because we check every variable via current_user
  if (AppConfig.userIsLoggedIn) {
    setCustomDimension({ id: 2, value: countryId });
    setCustomDimension({ id: 3, value: townshipId });
    setCustomDimension({ id: 4, value: townshipIsActive ? 'yes' : 'no' });
  }

  // Optional trackings ------------------------------------------------------------------------------
  previousPageUrl && track('setReferrerUrl', previousPageUrl);

  // Extract page load speed from Turbolinks event
  timing?.visitEnd
    && track('setPagePerformanceTiming', timing.visitEnd - timing.visitStart);

  // All trackings -----------------------------------------------------------------------------------
  track('setCustomUrl', window.location.href);
  track('setDocumentTitle', document.title);
  track('enableLinkTracking');

  track('trackPageView');
  track('deleteCustomVariables', 'page');
  previousPageUrl = window.location.href;
}

document.addEventListener('turbolinks:load', (event) => {
  matomoPromise.then(initialized => initialized && trackPage(event));
});
