const menuFill = (col) => {
  const $el = $('.js-menu-fill');

  if (!$el.length > 0) { return; }

  const $childrens = $('li', $el);
  const rest = $childrens.length % col;
  const itemToAppend = rest > 0 ? col - rest : 0;
  let result = '';

  for (let n = 0; n < itemToAppend; ++n) {
    result += '<li></li>';
  }
  $el.append(result);
};

document.addEventListener('turbolinks:load', () => {
  menuFill(3);
});
