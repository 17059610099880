/** * BOOSTRAP TABS TO ACCORDION ON SMALLER SCREENS  ** */

const getBreakpointConfiguration = (item) => {
  const data = $(item).data('responsive-breakpoint') || 'xs';

  let tabClass = '';
  let accordionClass = '';

  data.split(' ').forEach((i, breakPoint) => {
    tabClass += ` hidden-${breakPoint}`;
    accordionClass += ` visible-${breakPoint}`;
  });

  return { tabClass, accordionClass };
};

const setupResponsiveTabs = () => {
  $('.js-tab-responsive')
    .each((i, el) => {
      const config = getBreakpointConfiguration(el);
      $(el).tabCollapse(config);
    })
    .on('shown-accordion.bs.tabcollapse', (e) => {
      $(e.currentTarget).siblings('.panel-group').find('.panel-collapse').collapse('hide');
    })
    .on('shown-tabs.bs.tabcollapse', (e) => {
      $(e.currentTarget).find('li').first().tab('show');
    });
};

// TODO: fix tests for this
document.addEventListener('turbolinks:load TODO', () => {
  setupResponsiveTabs();
});
