import Turbolinks from 'turbolinks';
import { on } from 'src/lib/utils';
import I18n from 'i18n';

// After user successfully confirms a phone
$(document).on('phone.create.fm', () => {
  $('.js-phone-confirmation-link').trigger('updatePhone:done');
  document.querySelector('[data-id="profile_phone_form"]').reloadShow();
});

// After user successfully edit a name
on(document, 'inlineForm-submit', '[data-id="profile_name_form"]', (e) => {
  const fullName = e.querySelector('[data-full-name]')?.dataset?.fullName;

  fullName && $('.username').html(fullName);
});

// After user successfully edit a phone
on(document, 'inlineForm-submit', '[data-id="profile_phone_form"]', (e) => {
  const link = $('.js-phone-confirmation-link', e.target).attr('href');
  link && $.get(link);
});

// After user successfully edit language
on(document, 'inlineForm-submit', '[data-id="profile_locale_form"]', (e) => {
  const { lang } = e.querySelector('[data-lang]').dataset;

  I18n.locale = lang;
  Turbolinks.visit(window.location.href, { action: 'replace' });
});
