import TurboNativeAdapter from '@effectivastudio/turbo_native_adapter';

/*
  TurboNativeAdapter will resolve itself to Turbo or Turbolinks
*/

TurboNativeAdapter.getAuthToken = () => App.config.user.accessToken;
TurboNativeAdapter.shouldUpdatePushToken = () => App.config.user.id;

if (TurboNativeAdapter.IS_TURBO) {
  document.addEventListener('notifications:total_count:update', (e) => {
    TurboNativeAdapter.setBadgeCount(e.detail.totalCount || 0);
  });
}

App.Mobile = TurboNativeAdapter;

TurboNativeAdapter.start();
