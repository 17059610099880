import '@effectivastudio/page_visibility';

// CustomEvent polyfill is in patches/custom_event.js

// function onActive() {
// }

// function onIdle() {
// }

document.addEventListener('visibility', (e) => {
  App.isSuspended = !e.detail.pageIsActive;
  // e.detail.pageIsActive ? onActive() : onIdle();
});
